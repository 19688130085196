
.main-menu {

  .menu {
    li {
      a {
        padding: 2.5rem 3rem;
        font-weight: 700;
        color: $black;
        border-right: 3px solid #f8f7fa;

        &.active,
        &:hover {
          color: $white;
          background-color: $secondary-color;
        }
      }

      &:first-child {
        a {
          border-left: 3px solid #f8f7fa;
        }
      }

      &:last-child {
        a {
          border-right: 3px solid #f8f7fa;
        }
      }

      &.is-active {
        > a {

        }
      }
    }
  }

  .is-dropdown-submenu-parent {
    position: static;
  }

  .is-dropdown-submenu.menu {
    width: 100%;

    li {
      text-align: left !important;
      a {
        color: $black;
      }
    }
  }
}

