/** Wrapper Styles **/

a, i {
  transition: all 200ms linear;
}

div,
img {
  outline: none !important;
}

.wrapper {
  overflow: hidden;
  margin-top: 55px;
}

.section {
  padding: 50px 0;
}

.bg-grau {
  background-color: #f7f5f4;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}