.label {
  margin-bottom: 0.3rem;
  transition: all 200ms linear;

  &:hover {
    background: lighten($primary-color, 10%);
  }

  a {
    color: $white;
    text-transform: uppercase;
  }
}

.intro {
  background-color: #eff1f7;
  padding: 3.5rem 0;

  h1 {
    text-align: center;
  }
}

.icons {
  display: block;
  text-align: center;
  box-shadow: 0px 0px 18px 3px #ededed;
  padding: 3.5rem 0 2rem;

  &:hover {
    box-shadow: 0px 0px 18px 3px $dark-gray;
  }

  span {
    display: block;
    margin-top: 2rem;
  }
}

.kompentenzen-box-1,
.kompentenzen-box-2 {
  background-color: $secondary-color;
  color: $white;
  padding: 2rem 3.5rem;
  font-weight: 300;
  font-size: rem-calc(18);

  h5 {
    font-weight: 300;
  }
}
.kompentenzen-box-2 {
  background-color: $primary-color;
}

.float-boxes {
  position: relative;
  border-top: 150px solid #eff1f7;

  .box-1 {
    box-shadow: 0px 0px 18px 3px rgba($dark-gray, .7);
    padding: 3.5rem;
    margin-top: -125px;
    margin-bottom: -60px;
    position: relative;
    background: #fff;
  }

  .box-2 {
    background-color: $primary-color;
    color: $white;
    padding: 7rem;
    padding-bottom: 1.5rem;
    font-weight: 300;
    margin-bottom: 50px;

    h4 {
      color: $white;
      text-transform: none;
    }

    .button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover,
    .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
      border: 1px solid $white;
      color: $white;
      width: 70%;
      display: block;
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      font-size: rem-calc(18);
      font-weight: 300;
    }
  }
}

.highlight {
  background-color: $secondary-color;
  color: $white;
  font-size: rem-calc(18);
  font-weight: 300;
  padding: 2rem 12rem;

  h4 {
    color: $white;
  }
}

.tabs-title {
  height: 190px;
  @include flex;
}

.tabs-title > a {
  background-color: $primary-color;
  color: $white;
  font-size: rem-calc(25);
  font-weight: 300;
  text-transform: uppercase;
  @include flex-align-self(bottom);
}

.tabs-title > a:focus, .tabs-title > a:hover, .tabs-title > a[aria-selected='true'] {
  background-color: $secondary-color;
  color: $white;
  padding: 5.25rem 4.5rem;
}

.team {
  .cell {
    margin-bottom: 1.5rem;
  }
}

// BGs
.bg-kontakt {
  background-image: url('../img/content-img-start.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-tabs {
  background-image: url('../img/bg-tabs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}