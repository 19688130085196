
@include breakpoint(1024px down) {

}

@include breakpoint(medium down) {
  .wrapper {
    margin-top: 0;
  }

  .bg-tabs {
    height: auto;
  }

  .tabs-title {
    height: auto;
    width: 100%;

    > a {
      display: block;
      width: 100%;
      padding: 2.25rem 2.5rem;
    }
  }

  .tabs-title > a:focus, .tabs-title > a:hover, .tabs-title > a[aria-selected='true'] {
    background-color: #a0aecd;
    color: #fefefe;
    padding: 3.25rem 2.5rem;
  }

  .tabs-panel {
    padding: 2.25rem;
  }

  .footer {
    text-align: center;

    .large-3 {
      margin-bottom: 1.5rem;
    }
  }
}

@include breakpoint(small down) {

  .kompentenzen-box-1, .kompentenzen-box-2,
  .float-boxes .box-1,
  .float-boxes .box-2,
  form.white-background,
  .highlight {
    padding: 2rem;
  }

  .float-boxes {
    .box-1 {
      box-shadow: none;
    }
    .button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover,
    .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
      width: 100% !important;
    }
  }

  .label {
    width: 100%;
    white-space: normal;
  }
}
