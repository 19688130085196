@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Regular.ttf) format('truetype'),
  url(../fonts/OpenSans-Light.ttf) format('truetype'),
  url(../fonts/OpenSans-Bold.ttf) format('truetype')
}
@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Light.ttf) format('truetype')
}
@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Bold.ttf) format('truetype')
}

.h1, .h2, .h3, .h4, .h5,
h1, h2, h3, h4, h5 {
  text-transform: uppercase;
}

h1, h4 {
  color: $dark-gray;

  span {
    color: $black;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

ul.leistungen-list {
  margin-top: 2.5rem;

  li {
    text-align: center;
    font-weight: 300;
    list-style: none;
    border-bottom: 2px solid $white;
    padding: 0.65rem 0;
  }
}
