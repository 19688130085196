.mobil-menu {
  background: $primary-color url('../img/kw-logo-white.png') no-repeat left 0.96rem center;
  background-size: 50px;
}


.menu-icon::before {
  content: 'Menu';
  left: -60px;
  top: 1px;
  position: relative;
  display: inline-block;
  color: white;
  text-transform: uppercase;
}

// Off-Canvas

.off-canvas-btn {
  padding: 1rem 1rem 1.5rem;
  text-align: right;
}


.off-canvas {
  padding-top: 3.5rem;

  li {
    display: block;
    width: 100%;

    a {
      border-right: 0 none;
      border-bottom: 0 none;
      padding: 1rem 0.8rem;
      text-transform: uppercase;
      color: $black;


      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 0 none;
      }
    }

    .menu {
      padding-left: 1rem;
    }
  }
}