.header-top {
  background-color: $primary-color;
  padding: 1rem 0;
  color: $white;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;

  a {
    color: $white;
    padding-left: 1rem;
  }

  .grid-container {
    background: transparent url('../img/kw-logo-white.png') no-repeat left 0.96rem center;
    background-size: 0;
    transition: background-size 100ms linear;

    &.show-it {
      background-size: 45px;
    }
  }
}

.logo {
  img {
    position: relative;
    top: 17px;
  }
}