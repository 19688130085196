form.white-background {
  background-color: $white;
  padding: 3rem;
  margin: 4rem 0;
  box-shadow: 160px 0px 0px 56px rgba($secondary-color ,0.75);
}

legend,
label {
  color: $black;
  font-weight: 300;
}


.button.large.submit-btn {
  background-color: $secondary-color;
}